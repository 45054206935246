export default {
  "common": {
    "completeGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tam bələdçi"])},
    "selectBottomButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məzmununu oxumaq və ya paylaşmaq üçün aşağıdakı düyməyə basın."])},
    "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAHA&nbsp;ÇOX&nbsp;ÖYRƏNİN!"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paylaş"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["çıxış"])}
  },
  "header": {
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
      "growthCalculators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesablamalar"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitabxana"])},
      "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nəşrlər"])},
      "scientificBrochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmi broşurla"])},
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videolar"])},
      "materialsForParents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valideynlər üçün maarifləndirici materiallar"])},
      "healthyStools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlam nəcis"])},
      "complementaryFeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlavə qidalanma"])},
      "brochures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broşurlar"])},
      "breastfeeding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breastfeeding"])},
      "easyTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asan İzləmə"])},
      "aboutFriso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso haqqında"])}
    }
  },
  "footer": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tibb mütəxəssisləri üçün xüsusi material. Ana südü körpə üçün ən yaxşı qidadır."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daxilən daha güclü olsaq birlikdə daha çox təcrübə əldə edə bilərik!"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizi burada tapa bilərsiniz:"])},
    "textD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bütün hüquqlar qorunur"])},
    "textE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əsas brendlərimiz olan FRISO<sup>&reg;</sup> və FRISOLAC<sup>&reg;</sup> daxil olmaqla, lakin bununla məhdudlaşmayan bu veb-saytın məzmunu FrieslandCampina-nın qorunan əqli mülkiyyətidir."])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso haqqında"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso, dünyanın aparıcı süd şirkətlərindən biri olan FrieslandCampina tərəfindən Hollandiyada istehsal olunan və qablaşdırılan qlobal brenddir. Friso məhsullarımızdakı süd öz fermalarımızdan gəlir. Torpağın keyfiyyətindən tutmuş inəklərin bəslənməsinə qədər təsərrüfatlarımıza xüsusi diqqət yetiririk. Fermerlərimiz 150 ildən çox südçülük təcrübəsi olan fəxri fermer ailələrindəndir."])},
    "subtitleA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso-nu unikal edən məhz budur!"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün istehsal zəncirinə tam nəzarət"])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso premium uşaq qidalarını istehsal etmək üçün yalnız ən yüksək keyfiyyətli inqrediyentlərdən və ən innovativ istehsal üsullarından istifadə edilir. Yüksək keyfiyyətli və asanlıqla həzm olunan süd zülalları istehsal etmək üçün tək emal və aşağı istiliklə zülal modifikasiyası vacibdir. İnəklərin bəslənməsindən tutmuş  son məhsula qədər bütün istehsal zəncirinə nəzarət etməklə biz bütün dünyada səhiyyə işçiləri və analar üçün Friso məhsullarının keyfiyyətinə zəmanət veririk."])},
    "subtitleB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otdan hazır məhsula qədəR"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/logo_en.png"])},
    "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/lifecycle_az.png"])},
    "imageCUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/about/from-grass-to-glass_az.png"])}
  },
  "easyTracking": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asan İzləmə"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asan İzləmə Sistemi nədir?"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hollandiyadakı öz fermalarımızdan başlayaraq, Friso qatışığının istehsalının hər addımına ciddi nəzarət edə bilərsiniz."])},
    "cardA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermalarımız"])},
    "cardB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süd<br/>növləri"])},
    "cardC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qablaşdırma"])},
    "cardD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyfiyyətə son<br/>nəzarət"])},
    "cardE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İxracın<br/>təsdiqi"])}
  },
  "poop": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı həzm körpənizin böyüməsi və inkişafı üçün vacibdir. Uşağınızın həzm sisteminin sağlamlığının ən gözə çarpan göstəricisi onun nəcisidir! Uşağınıza yaxşı defekasiyaya nail olmaq üçün necə kömək edə bilərsiniz."])},
    "colors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rənglər"])},
    "shapes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formalar"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı"])},
    "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pis"])},
    "goodColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı Rənglər"])},
    "goodColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu <b>rənglər</b> normal dəyişikliklərdir."])},
    "greenishCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sarı qəhvəyi"])},
    "coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qəhvəyi"])},
    "coffeeYellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaşımtıl qəhvəyi"])},
    "darkGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tünd yaşıl"])},
    "badColorsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pis Rənglər"])},
    "badColorsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu <b>rənglər</b> körpələrdə qanaxma və ya həzm problemlərinin əlamətlərini göstərə bilər."])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qırmızı"])},
    "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qara"])},
    "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağ"])},
    "withRedBars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qırmızı zolaqlarla"])},
    "goodShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı formalar"])},
    "goodShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunlar asanlıqla xaric oluna  bilən ideal <b>formalardır</b>."])},
    "spongy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süngərli"])},
    "sausage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yumşaq kolbasa"])},
    "badShapesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyğun olmayan formalar"])},
    "badShapesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu <b>formalar</b> susuzlaşdırma, lif çatışmazlığı, pis qidalanma və / və ya stress əlamətlərini göstərir."])},
    "parsley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cəfəri"])},
    "parsleyImageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parsley_en"])},
    "oats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yulaf"])},
    "grape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzüm"])},
    "chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toyuq Nagetsləri"])},
    "rabbit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovşan nəcisi"])}
  },
  "complementaryFeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlavə qidalanma"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinir sisteminin inkişafı və qidalanma arasında olan əlaqə"])},
    "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop_en.png"])},
    "imageA2xUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/complementary-feeding/table-desktop2x_en.png"])}
  },
  "breatsfeeding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktasiya"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Həkimlər həyatın ən azı ilk altı ayında yalnız ana südü ilə qidalandırmağı məsləhət görürlər"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ümumdünya Səhiyyə Təşkilatı (ÜST) ilk altı ay ərzində yalnız ana südü ilə qidalanmağı daha sonra yaşa uyğun və təhlükəsiz qidaların tətbiqini, 2 yaş və ya ondan yuxarı olana qədər ana südü ilə qidalandırmanın davam etdirilməsini tövsiyə edir"])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, anaların müxtəlif vəziyyətlərindən asılı olaraq dəyişə bilər: bir çoxları işə daha erkən qayıtmalı olur, digərləri uşaqlarını erkən yaşlarından daha müstəqil etmək istəyə bilər və ya ata ilə qidalanma məsuliyyətini bölüşdürə bilər ki, onlar da bağlılıq hiss etsinlər."])},
    "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cəmiyyət yeni doğulmuş körpəni necə və nə vaxt qidalandırmaq üçün təzyiq göstərir. Bu mövzuda, heç kim sizə uşağınız üçün nəyin ən yaxşı olduğunu deyə bilməz, çünki onun həyat tərzini və vərdişlərini hamıdan daha yaxşı bilən yalnız siz olacaqsınız. Beləliklə, körpəniz üçün ən yaxşı qidanın ana südü olduğu bir faktdır, ona görə də sizə ən azı həyatının ilk altı ayında körpənizi əmizdirməyə cəhd etməyi məsləhət görürük."])}
  },
  "materialsVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videolar"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasiyentlərin valideynlərini maraqlandıran mövzuları göstərmək üçün video seçin."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktozanın Faydaları"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beneficios-de-la-lactosa-consumidores.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-b.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpəmin laktozaya qarşı dözümsüz olduğunu necə bilə bilərəm?"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video-lactosa-para-consumidores-pisa.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-materials/video-preview-c.png"])}
      }
    }
  },
  "publications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nəşrlər"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso məhsullarının elm əsasında olması haqqında ətraflı öyrənmək üçün məqalələri seçin."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimal termo emalla kommersial olaraq əlçatan qatışıqla qidalanan çin körpələrində mədə-bağırsaq simptomlarının tezliyinin azalması:çarpaz müşahidə tədqiqatı"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xiao Yang Sheng, Vanitha Buthmanaban, Glenn A.A. van Lieshout and Panam Parikh"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020-sheng-et-al-jnme-disminucion-de-sintomas-gastrointestinales-en-lactantes-alimentados-con-una-formula-minimamente-procesada.pdf"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsan Qidalanmasında Laktozanın Əhəmiyyəti: Meksika Konsensus Yığıncağının nəticələri"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrique Romero-Velarde, Dagoberto Delgado-Franco, Mariana García-Gutiérrez, Carmen Gurrola-Díaz, Alfredo Larrosa-Haro, Ericka Montijo-Barrios, Frits AJ Muskiet, Belinda Vargas-Guerrero and Jan Geurts"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consenso-de-lactosa-traduccion-documento.pdf"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə Qatışıqlarının model Sistemlərində Miselyar Kalsium Fosfatın laboratoriya şəraitində Mədə Koaqulyasiyasına və Süd Zülalının Həzminə Təsiri"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorn Huppertz and Tim T. Lambers"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huppertz-2020-influence-of-calcium-phosphate-on-in-vitro-coagulation-and-digestion-of-milk.pdf"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emal süd zülalının həzminə və ümumi fizioloji nəticələrə necə təsir edə bilər: sistematik  baxış"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glenn A. A. van Lieshout, Tim T. Lambers, Marjolijn C. E., Bragt and Kasper A. Hettinga"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lieshout-2019-como-el-procesamiento-puede-afectar-la-digestion-de-proteinas-de-la-leche-y-los-resultados-fisiologicos-generales.pdf"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə süd qatışıqları allergen aktivliyi və T-hüceyrə və sitokin reaksiyalarının induksiyası ilə fərqlənir"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H. Hochwallner, U. Schulmeister, I. Swoboda, M. Focke-Tejkl, R. Reininger, V. Civaj, R. Campana, J. Thalhamer, S. Scheiblhofer, N. Balic, F. Horak, M. Ollert, N. G. Papadopoulos, S. Quirce, Z. Szepfalusi, U. Herz, E. A. F. van Tol and S. Spitzauer & R. Valenta"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hochwallner-2016-infant-milk-formuls-differ-regrding-their-allergenic-activity-and-induction-of-t-cell-and-cytokine-responses.pdf"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaxtından əvvəl doğulmuş körpələrin gec və az miqdarda qidalanması: Avropa Pediatrik Qastroenterologiya, Hepatologiya və Qidalanma Cəmiyyətinin Qidalanma Komitəsinin mövqe sənədi"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexandre Lapillonne, Jiri Bronsky, Cristina Campoy, Nicholas Embleton, Mary Fewtrell, Nataša Fidler Mis, Konstantinos Gerasimidis, Iva Hojsak, Jessie Hulst, Flavia Indrio, Christian Molgaard, Sissel Jennifer Moltu, Elvira Verduci and Magnus Domellöf"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feeding-the-late-and-moderately-preterm-infant-lapillone-2019_en.pdf"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südü allergiyasının qarşısının alınması, diaqnostikası və müalicəsi üçün strategiyalar və gələcək imkanlar"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benjamin Zepeda-Ortega, Anne Goh, Paraskevi Xepapadaki, Aline Sprikkelman, Nicolaos Nicolaou, Rosa Elena Huerta Hernandez, Amir Hamzah Abdul Latiff, Miu Ting Yat, Mohamed Diab, Bakr Al Hussaini, Budi Setiabudiawan, Urszula Kudla, R. J. Joost van Neerven, Leilani Muhardi and John O. Warner"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.pdf"])}
      }
    }
  },
  "scientificBrochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmi broşurla"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə və uşaqların qidalanması haqqında ətraflı öyrənmək üçün maraqlı olan mövzuları seçin."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk formula with different glycation profile influences gastrointestinal outcomes in Chinese infants"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["milk-formula-with-different-glycation-profile-influences-gi-outcomes-in-chinese-infants_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Brochure 2020"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product-brochure-2020-azerbaijan_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/scientific-brochures/item-b.png"])}
      }
    }
  },
  "brochures": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broşurlar"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maraq doğuran mövzuları seçin."])},
    "articles": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Cows' Milk Protein Allergy (CMPA)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-cows-milk-allergy_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patients' guide for the management of Functional Gastrointestinal Disorders (FGIDs)"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booklet-functional-gastrointestinal-disorders_en.pdf"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/brochures/item-b.png"])}
      }
    }
  },
  "libraryVideos": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videolar"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpə və uşaqların qidalanması haqqında ətraflı öyrənmək üçün maraqlı olan mövzuları seçin."])},
    "videos": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süd yağının həzm edilməsi"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digestion-de-la-grasa-lactea.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-a.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktozanı azaltmaq? İki dəfə düşünün!"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reducir-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-b.png"])}
      },
      "c": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktozaya qarşı dözümsüzlük"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intolerancia-a-la-lactosa-nuevo-logo-hcp.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-c.png"])}
      },
      "d": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friesland Campina - Fermer Hekayəsi"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-farmer-story_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-d.png"])}
      },
      "e": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südünə qarşı allergiyanın qarşısının alınması, diaqnostikası və müalicəsi üçün strategiyalar və gələcək imkanlar"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frieslandcampina-publication-series-zepeda-et-al-strategies-and-future-opportunities-for-the-prevention-diagnosis-and-management-of-cow-milk-allergy_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-e.png"])}
      },
      "f": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tək və ikiqat emal"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glycation-single-versus-double-processing_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-f.png"])}
      },
      "g": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktoza - I hissə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-i_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-g.png"])}
      },
      "h": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktoza - II hissə"])},
        "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lactose-part-ii_en.mp4"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/videos-library/video-preview-h.png"])}
      }
    }
  },
  "calculators": {
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlam körpə hesablamaları."])},
    "boy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oğlan"])},
    "girl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qız"])},
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məlumatları düzgün doldurun"])},
    "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesablamaq"])},
    "percentile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faiz"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nəticələr"])},
    "headCircumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başın dairəsi"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boy"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzunluq"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çəki"])},
    "weightHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çəki ─ Boy"])},
    "weightLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çəki ─ Uzunluq"])},
    "dateBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğum günü"])},
    "dateMeasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölçmə tarixi"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaş"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylar"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İllər"])},
    "ageRangeYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ildən ", _interpolate(_list(1)), " ilə qədər"])}
  },
  "home": {
    "banners": {
      "a": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Friso AR"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflyuks üçün: kərənay ağacının kitrəsi və hidrolizə olunmuş zulalla"])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/1/packshot_en.png"])}
      },
      "b": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulyator"])},
        "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜST-nın güvən möhürü ilə mən indi körpələrin böyümə faizlərini dərhal hesablaya bilirəm."])},
        "imageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/slides/2/device_en.png"])}
      }
    },
    "columns": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məhsullarımızın tam çeşidini ilə tanış olun və körpəniz üçün düzgün  seçim edin."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizin üçün hazırladığımız ən son elmi materialları yoxlayın ki,  daima yeniliklərdən və elmi nailiyyətlərdən xəbərdar olasınız."])},
      "textC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu maarifləndirici materialları paylaşmaqla valideynlərə hətta uzaqdan da kömək edin."])}
    },
    "app": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni tətbiqimiz nəhayət sizin üçün burada!"])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Tətbiqimizi artıq iPhone və ya Android telefonları üçün yükləməyin əlçatan olduğunu bildirməkdən çox məmnunuq!"])},
      "imageAUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/app-devices_en.png"])},
      "imageBUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/home/qr-code_az.png"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha heç bir şübhəniz olmasın..."])},
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ünvanınızı yazın və ən qısa zamanda sizinlə əlaqə saxlayacayıq."])},
      "inputA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ünvanınızı daxil edin"])},
      "inputB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sualınızı bura yazın"])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlaqə tələb edin"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred!"])},
      "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending..."])}
    }
  },
  "login": {
    "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu vebsayt yalnız səhiyyə işçiləri üçün nəzərdə tutulub."])},
    "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əgər girişiniz üçün artıq icazə verilibsə, aşağıdakı təfərrüatları daxil edin.<br/>Əgər siz səhiyyə mütəxəssisisinizə və bu vebsayt üçün etiqadnaməyə malik deyilsinizsə, <br/><u>Friso ilə əlaqə saxlayan şəxsdən soruşun</u>."])},
    "inpUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadəçi adı"])},
    "inpPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daxil et"])},
    "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials!"])}
  },
  "portfolio": {
    "banner": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana südü uşaqların sağlam böyüməsi və inkişafı üçün ən yaxşı qidadır."])},
      "textB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana südü ilə qidalandırma seçimi olmadıqda, məhsul çeşidimiz xüsusi ehtiyacları olan və ya olmayan hər bir körpə üçün çıxış yolları təqdim edir."])}
    },
    "allProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün məhsullar"])},
    "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xüsusi təkliflər"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gündəlik"])},
    "notice": {
      "textA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana südü körpələrin sağlam böyüməsi və inkişafı üçün ən yaxşı qidadır. Analar adekvat qəbulu və keyfiyyətli ana südünü saxlamağa kömək etmək üçün düzgün ana qidalanması ilə bağlı təlimat almalıdırlar. Qismən və ya tam süni qidalanmanın və ya digər əlavə qidaların və ya içkilərin lazımsız tətbiqi ana südü ilə qidalanmaya mənfi təsir göstərə bilər ki, bu da geri dönməz ola bilər. Analar ana südü əvəzedicilərindən istifadə etməyə qərar verməzdən əvvəl və ya ana südü ilə qidalandırmaqda çətinlik çəkdikdə həkimləri ilə məsləhətləşməli və sosial və iqtisadi vəziyyəti nəzərə almalıdırlar. Ana südü əvəzedicilərinin istifadəsi, hazırlanması və saxlanması ilə bağlı təlimatlara diqqətlə əməl edilməlidir, çünki yersiz və ya lazımsız istifadə sağlamlıq üçün təhlükəli ola bilər."])},
      "buttonA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OXUDUM VƏ ANLADIM"])}
    }
  },
  "product": {
    "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TƏRKİBİ"])},
    "preparation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAZIRLANMASI"])},
      "tableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tövsiyə olunan qidalanma cədvəli"])},
      "table": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["<img class=\"table\" src=\"", _interpolate(_list(0)), "\"/><p><strong>1 ölçü qaşığı təxminən ", _interpolate(_list(1)), " qramdır.<br/>Məhsulun düzgün hazırlanmaması, qaynanmamış su, sterilizə edilməmiş qablardan istifadə uşağınıza zərər verə bilər.</strong></p>"])},
      "instructions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazırlanması və<br/>istifadəsi üçün təlimat"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uşağınızın sağlamlığı üçün hazırlıq və istifadə qaydalarına ciddi riayət edin:"])},
        "number1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əllərinizi və qablarınızı təmiz su ilə yaxşıca yuyun."])},
        "number2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suyu 5 dəqiqə qaynadın və ilıq olana qədər soyudun. Əgər mümkün olmasa, otaq temperaturunda sterilizə edilmiş su istifadə edilə bilər. Mikrodalğalı sobada qızdırmayın."])},
        "number3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butulkanı istifadə etməzdən əvvəl yuyun və suda qaynadın."])},
        "number4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göstərilən miqdarda suyu butulkaya tökün."])},
        "number5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ml suya 1 xörək qaşığı əlavə etmək üçün qablaşmanın içindəki ölçü qaşığından istifadə edin."])},
        "number6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toz tamamilə həll olunana qədər yaxşıca qarışdırın və ya silkələyin."])},
        "number7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperaturu yoxlayın və körpəyə yedizdirin."])},
        "number8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaşığı yerinə qoyun və qapağı bağlayın."])},
        "number9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazırlanmış qidanı ən geci 1 saat ərzində istifadə edin."])},
        "number10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstifadə edilməmiş qatışığı atın."])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Diqqət:<br/>Qatışığı hazırladıqdan sonra ən geci bir saat ərzində istifadə edin."])}
      }
    },
    "nutricionalTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QİDALANMA CƏDVƏLİ"])},
    "frisoGold1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asanlıqla həzm olunan zülal"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən - 6 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold 1-in tərkibində palma yağı yoxdur</li><li>Friso Gold 1 LockNutri texnologiyasına malikdir: minimal termo emal zülalların qlikasiyasını məhdudlaşdırır, həzmə müsbət təsir göstərir</li><li>Mövcud ədəbiyyatlara əsasən  Qalakto-oliqosaxaridər (QOS) prebiotik faydalı bakteriyaların çoxalmasını stimullaşdırır və bağırsaq sağlamlığını qorumağa kömək edir</li><li>DHA və ARA öyrənmə və idrak inkişafını dəstəkləyir</li><li>Tərkibində immuniteti dəstəkləyən nukleotidlər var</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deminerallaşdırılmış süd zərdab, Yağsızlaşdırılmış süd zərdabı, Bitki yağları, Laktoza, QOS, Maltodekstrin, Balıq yağı, Xolin xlorid, Tək hüceyrəli yağ (Mortierella alpina), Taurin, Lesitin (emulqator), <strong>Minerallar</strong> (Kalsium fosfat, Kalium xlorid, Kalsium karbonat, Natrium xlorid, Maqnezium sitrat, Dəmir sulfat, Kalsium hidroksid, Sink sulfat, Mis sulfat, Manqan sulfat, Kalium yodid, Natrium selen), <strong>Vitaminlər</strong> (L-natrium askorbat, Qarışıq tokoferollar, L-askorbil-palmitat, DL-α-tokoferol asetat, Nikotinamid, D-kalsium pantotenat, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Riboflavin, Fol turşusu, Fitomenadion, D-biotin, Xolekalsiferol, <strong>Nukleotidlər</strong> (Dinatrium-uridin-5-monofosfat, Dinatrium-inozin-5-monofosfat, Sitidin-5-monofosfor turşusu, Adenozin-5-monofosfor turşusu, Dinatrium quanozin-5-monofosfat)"])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-2_az.svg"])}
    },
    "frisoGold2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asanlıqla həzm olunan zülal"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 aydan 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold 2-nin tərkibində palma yağı yoxdur</li><li>Friso Gold 2 LockNutri texnologiyasına malikdir: minimal termo emal həzmə müsbət təsir göstərərək zülalların qlikasiyasını məhdudlaşdırır</li><li>Mövcud ədəbiyyatlara əsasən  Qalakto-oliqosaxaridər (QOS) prebiotik faydalı bakteriyaların çoxalmasını stimullaşdırır və bağırsaq sağlamlığını qorumağa kömək edir</li><li>DHA və ARA, öyrənmə və idrak inkişafını dəstəkləyir</li><li>Tərkibində immuniteti gücləndirən nukleotidlər var</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağsızlaşdırılmış süd, Deminerallaşdırılmış süd zərdabı, Bitki yağları, Laktoza, QOS, Maltodekstrin, Balıq yağı, Tək hüceyrəli yağ (Mortierella alpina), Xolin xlorid, Taurin, Lesitin (emulqator). <strong>Minerallar:</strong> (Kalsium fosfat, Kalium xlorid, Kalsium karbonat, Natrium xlorid, Maqnezium sitrat, Dəmir sulfat, Kalsium hidroksid, Sink sulfat, Mis sulfat, Manqan sulfat, Kalium yodid, Natrium selen) <strong>Vitaminlər</strong> (L-natrium askorbat, Qarışıq tokoferollar, DL - α-tokoferol asetat, L-askorbil palmitat, Nikotinamid, Kalsium D-pantotenat, Tiamin hidroxlorid, Piridoksin hidroxlorid, Retinol asetat, Riboflavin, Fol turşusu, Fitomenadion, D-biotin, Xolekalsiferol, <strong>Nukleotidlər</strong> (Dinatrium uridin-5-monofosfat, Sitidin-5-monofosfor turşusu, dinatrium inozin - 5-monofosfat, Adenozin-5-monofosfor turşusu, Dinatrium-quanozin-5-monofosfat)."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-4_az.svg"])}
    },
    "frisoGold3": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso Gold 3"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> Gold 3"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asanlıqla həzm olunan zülal"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 yaşdan 3 yaşa qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold 3-ün tərkibində palma yağı yoxdur</li><li>Friso Gold 3 LockNutri texnologiyasına malikdir: minimal termo emal həzmə müsbət təsir etmək üçün zülalların qlikasiyasını məhdudlaşdırır</li><li>Mövcud ədəbiyyatlara əsasən  Qalakto-oliqosaxaridər (QOS) prebiotik faydalı bakteriyaların çoxalmasını stimullaşdırır və bağırsaq sağlamlığını qorumağa kömək edir</li><li>Tərkibində immuniteti gücləndirən nukleotidlər var</li><li>DHA və ARA öyrənmə və inkişafı dəstəkləyir</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağsızlaşdırılmış süd, Quru qlükoza siropu, Bitki yağları, Saxaroza, QOS, Laktoza, Balıq yağı, Soya lesitini (emulqator), Xolin xlorid, İnozit, Taurin, Tək hüceyrəli yağı (Mortierella alpina), <strong>Minerallar</strong> (Kalsium karbonat, Dəmir sulfat, Sink sulfat, Manqan sulfat, Mis sulfat, Natrium selen, Kalium yodid), <strong>Vitaminlər</strong> (L-askorbat natrium, Qarışıq tokoferollar, L-askorbil palmitat, DL-α-tokoferol asetat,  Nikotinamid,  Kalsium D-pantotenat, Retinol-asetat, Riboflavin, β-karotin, Tiamin hidroxlorid, Piridoksin hidroxlorid, Fol turşusu, Fitomenadion, Xolekalsiferol, D-biotin, <strong>Nukleotidlər</strong> (Dinatrium uridin-5'-monofosfat, Sitidin-5'-monofosfor turşusu, Dinatrium inozin-5-monofosfat, Adenozin-5'-monofosfor turşusu, Dinatrium quanozin - 5'-monofosfat)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-6_az.svg\"/><p>Məhsulun düzgün hazırlanmaması, qaynanmamış su, sterilizə edilməmiş qablardan istifadə uşağınıza zərər verə bilər.</p><img class=\"table achtung\" src=\"/img/products/preparation-table-7_en.png\"/>"])}
    },
    "frisoVom1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso VOM 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> VOM 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaytarma, qəbizlik və sancının qidalanma ilə müalicə edilməsi"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən - 6 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Vom Comfort 1 LockNutri - yə malikdir: minimal termo emal həzmə müsbət təsir göstərən zülalın qlikasiyasını məhdudlaşdırır</li><li>Friso Vom Comfort 1 Keçibuynuzu ağacının lifi (KBAL) ilə  qaytarma, qəbizlik və sancı hallarının tezliyini azaltmaqda effektivdir.</li><li>Keçibuynuzu ağacının liflərinin aşağıdakı hallarda təsirli olduğu göstərilmişdir:<ul><li>Sancılarda KBAL və Qalakto-oliqosaxaridər (QOS) faydalı bağırsaq mikroflorasının inkişafını stimullaşdırır</li><li>Qəbizlikdə KBAL nəcisin yumşalmasına kömək edir və onun tezliyini normallaşdırır</li><li>Qaytarmada KBAL qatışığı qatılaşdırır, bununla da qaytarmanın həcmini və tezliyini azaldır</li></ul></li><li>DHA və ARA öyrənmə və inkişafı dəstəkləyir.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağsızlaşdırılmış süd, Deminerallaşdırılmış süd zərdabı, Bitki yağları,  Laktoza, Keçibuynuzu ağacının lifləri, QOS, Maltodekstrin, Zərdab zülal konsentratı, Balıq yağı, Xolin xlorid, Tək hüceyrəli yağ (Mortierella alpina), İnozit, Taurin, Lesitin (emulqator), <strong>Minerallar</strong> (Kalium sitrat, Tri-kalsium fosfat, Kalsium karbonat, Natrium xlorid, Maqnezium sitrat, Kalium xlorid, Kalsium hidroksid, Dəmir sulfat, Sink sulfat, Mis sulfatı, Manqan sulfatı, Kalium yodid, Natrium selen), <strong>Vitaminlər</strong> (Natrium L-askorbat, L-askorbil palmitat, Qarışıq tokoferol konsentratı, Kalsium D- pantotenat, DL- α-tokoferol asetat, Nikotinamid, Tiamin hidroxlorid, Riboflavin, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Fitaminadion, D- biotin, Xolekalsiferol), <strong>Nukleotidlər</strong> (Dinatrium uridin-5'-monofosfat, Sitidin-5'-monofosfat turşusu, Dinatrium inozin-5'-monofosfat, Adenozin-5'-monofosfat, Dinatrium quanozin-5'-monofosfat)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-9_az.svg\"/><p>1 ölçü qaşığı təqribən 4,3 qramdır<br/>100 ml hazır körpə süd qatışığı ~ 13 q toz (3 ölçü qaşığı) + 90 ml su</p><p>* Uşağınızın gündəlik qidalanma miqdarı ilə bağlı pediatrınızla məsləhətləşin</p>"])}
    },
    "frisoVom2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso VOM 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> VOM 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaytarma, qəbizlik və sancının qidalanma ilə müalicə edilməsi"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 aydan 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Vom Comfort 2 LockNutri-yə malikdir: minimal termo emal həzmə müsbət təsir edərək zülalların qlikasiyasını məhdudlaşdırır</li><li>Friso Vom Comfort 2  - Keçibuynuzu ağacının lifi (KBAL) ilə  qaytarma, qəbizlik və sancı tezliyini effektiv şəkildə azaldır</li><li>Keçibuynuzu ağacının liflərinin aşağıdakı hallarda təsirli olduğu göstərilmişdir:<ul><li>Sancılarda KBAL və Qalakto-oliqosaxaridər (QOS) faydalı bağırsaq mikroflorasının inkişafını stimullaşdırır</li><li>Qəbizlikdə KBAL nəcisin yumşalmasına kömək edir və onun tezliyini normallaşdırır</li><li>Qaytarmada KBAL qatışığı qatılaşdırır, bununla da qaytarmanın həcmini və tezliyini azaldır</li></ul></li><li>DHA və ARA öyrənmə və inkişafı dəstəkləyir.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağsızlaşdırılmış süd, Deminerallaşdırılmış süd zərdab, Bitki yağları, Laktoza, Keçibuynuzu ağacının lifləri, QOS, Maltodekstrin, Balıq yağı, Xolin xlorid, Tək hüceyrəli yağ (Mortierella alpina),Taurin, Lesitin (emulqator), <strong>Minerallar</strong> (Dikalsium fosfat, Kalium sitrat, Tri-kalsium fosfat, Kalsium karbonat, Natrium xlorid, Maqnezium sitrat, Kalium xlorid, Maqnezium fosfat, Dəmir sulfat, Kalsium hidroksid, Sink sulfat, Mis sulfat, Manqan sulfat, Kalium yodid, Natrium selen), <strong>Vitaminlər</strong> (Natrium L-askorbat, L-askorbil palmitat, Tokoferol konsentratı, Kalsium -D- pantotenat, DL-α-tokoferol asetat, Nikotinamid, Tiamin hidroxlorid, Riboflavin, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Fitamenadion, D-biotin, Xolekalsiferol, <strong>Nukleotidlər</strong> (Dinatrium uridin-5'-monofosfat, Sitidin-5'-monofosfat turşusu, Dinatrium inozin-5'-monofosfat, Adenozin-5'-monofosfor turşusu, Dinatrium quanozin-5'-monofosfat)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-4_az.svg\"/><p>1 ölçü qaşığı təqribən 5,0 qramdır<br/>100 ml hazır körpə qatışığı ~ 15 q toz (3 ölçü qaşığı) + 90 ml su</p><p>* Uşağınızın gündəlik qidalanma miqdarı ilə bağlı pediatrınızla məsləhətləşin<br/>** 6 aydan sonra körpənizin əlavə qidalara ehtiyacı olur.</p>"])}
    },
    "frisolacPremature": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Premature"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Premature"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaxtından əvvəl doğulmuş və az çəkisi olan körpələr üçün."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən müddətli yaşa və ya çəkiyə qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>ESPGHAN tərəfindən vaxtından əvvəl doğulmuş körpələr üçün tövsiyə edildiyi kimi yüksək səviyyədə zülal, enerji, mikroelementlər və doymamış yağ turşuları ilə təmin olunmaq üçündür.</li><li>Frisolac Premature – un tərkibində qismən hidrolizə edilmiş zərdab zülalları və yüksək dərəcədə hidrolizə olunmuş kazein zülalları var.</li><li>Doymamış yağ turşuları,  DHA, EPA, ARA körpənin beyin və görmə inkişafna kömək edir</li><li>OZT (orta zəncirli triqleserid) yağları enerji tələbatını ödəyir.</li><li>Qalakto-oliqosaxaridlər (QOS) prebiotiklərin yaxşı bağırsaq mikroflorası üçün faydalı olduğu tədqiqatlardan məlumdur.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quru süd, (Laktoza, Zərdab zülalı hidrolizatı), Bitki yağları, Maltodekstrin, Kazein hidrolizatı, Emulqator (limon turşusunun mono və diqliseridlərinin efirləri) QOS, Kalsium fosfat, Balıq yağı, Tək hüceyrəli yağ (Mortierella alpina), Kalsium karbonat, Maqnezium xlorid, Natrium xlorid, Natrium L- askorbat, Mezo-inozitol, Xolin xlorid, Taurin, Dəmir sulfat, DL-α-tokoferol asetat, Sink sulfat, Nikotinamid, Emulqator (Günəbaxan lesitini), L-karnitin, Sitidin-5'-monofosfor turşusu, L-askorbil palmitat, Kalsium D pantotenat, Dinatrium uridin-5'-monofosfat, Adenozin-5'-monofosfor turşusu, Dinatrium quanozin-5'-monofosfat, Retinil asetat, Dinatrium inozin-5'-monofosfat, Mis sulfat, Riboflavin, Tiamin hidroxlorid, Piridoksin hidroxlorid, Fol turşusu, Kalium yodid, Fitaminadion, Xolekalsiferol, Natrium selen, D-biotin, Manqan sulfat, Sianokobalamin."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Frisolac<sup>®</sup> Premature-un tərkibi gündə hər kiloqram bədən çəkisi üçün hazırlanmış qatışığın 150 ml istehlakına əsaslanı.</p><img class=\"table\" src=\"/img/products/preparation-table-5_az.svg\"/><p><strong>1 ölçü qaşığı təxminən 5,2 qramdır.</strong></p>"])}
    },
    "frisolacGoldSinLactosa": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Sin Lactosa"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Sin Lactosa"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körpələrdə laktoza dözümsüzlüyü və kəskin diareyanın müalicəsi üçün qida"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Sink ishalın şiddətini və müddətini azaldır və patogenlərin daha yaxşı təmizlənməsinə imkan verir.</li><li>Optimal dəmir tərkibi infeksiyalı ishalın müddətinin uzanma riski olmadan körpələrin düzgün artımını və inkişafını təmin edir.</li><li>Orta Zəncirli Triqliseridlər (OZT) bağırsaqlara əlavə təzyiq olmadan asanlıqla sorulan enerji mənbəyini təmin edir.</li><li>Nişasta: yenidən hidrasiya terapiyasında uğursuzluğu azaltmaq üçün göstərilmişdir.</li><li>İnulin: Həzm sistemini və nəcisin quruluşunu dəstəkləyir</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qlükoza siropu, Bitki yağları, Tək hüceyrəli yağ (Mortierella alpina), Zərdab zülalı konsentratı, Nişasta, Natrium kazein, Frukto-oliqosaxaridlər (FOS) (inulin), Kalium xlorid, Kalium Fosfat, Xolin bitartrat, Balıq yağı, Maqnezium fosfat, Kalsium karbonat, Natrium sitrat, Kalium sitrat, Tək hüceyrəli yağ (Mortierella alpina), Yağsızlaşdırılmış quru süd, Natrium L-askorbat, Taurin, Mezo-inozitol, Günəbaxan lesitin (emulqator), Sink sulfat, Dəmir sulfat, L-karnitin tartrat, L-askorbil palmitat, Dinatrium uridin-5' - monofosfat, Kalsium D-pantotenat, DL-α-tokoferil asetat, Nikotinamid, Sitidin-5'-monofosfor turşusu, Antioksidant (tokoferol zəngin ekstrakt), Dinatrium inozin-5'-monofosfat, Adenozin-5'- monofosfor turşusu,  Dinatrium quanozin-5' – monofosfat, Mis sulfat, Riboflavin, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Manqan sulfat, Kalium yodid, Fol turşusu, Fitomenadion, Natrium selen, D-Biotin, Xolekalsiferol, Sianokobalamin"])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_az.svg\"/><p>1 ölçü qaşığı təqribən 4,3 qramdır<br/>100 ml hazır körpə süd qatışığı ~ 13 q toz (3 ölçü qaşığı) + 90 ml su</p><p>* Uşağınızın gündəlik qidalanma miqdarı ilə bağlı pediatrınızla məsləhətləşin<br/>** 6 aydan sonra körpənizin əlavə qidalara ehtiyacı olur.<br/>*** 6 ayından sonra 180 ml suya bir qaşıq əlavə etməlisiniz.</p>"])}
    },
    "frisoPep": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso PEP"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> PEP"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südü zülallarına olan allergiyanın yüngül və orta dərəcəli simptomlarının müalicəsi üçün"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP-in  tərkibində yüksək dərəcədə hidrolizə edilmiş kazein zülalları var: peptidlərin 60%-i 3 amin turşusundan ibarətdir.</li><li>Sağlam bağırsaq florası üçün GOS</li><li>DHA və ARA öyrənmə və idrak inkişafını dəstəkləyir</li><li>Laktozasız, hər hansı zülal çirklənməsinin qarşısını almaq üçün.</li><li>İmmuniteti dəstəkləyən nukleotidlərlə.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quru  qlükoza siropu, Laktoza, Bitki yağları, Zərdab zülalı hidrolizatı, QOS, limon turşusunun mono və diqliseridlərinin efirləri (emulqator), Xolin xlorid, L-tirozin, L-fenilalanin,Taurin, Mezo-inozitol, Lesitin (emulqator), L-karnitin, <strong>Minerallar:</strong> Kalsium fosfat, Maqnezium xlorid, Natrium sitrat, Kalium xlorid, Natrium xlorid, Kalium sitrat, Dəmir sulfat, Sink sulfat, Manqan sulfat, Kalium yodid, Natrium selen, <strong>Vitaminlər:</strong>  (Natrium -L- askorbat, DL-α-tokoferol asetat, L-askorbil palmitat, Nikotinamid, Kalsium -D- pantotenat, Riboflavin, Retinol asetat, Tiamin hidroxlorid, Piridoksin hidroxlorid, Fol turşusu, Fitomenadion, D-biotin, Xolekalsiferol, Sianokobalamin, <strong>Nukleotidlər</strong> (Sitidin-5'-monofosfor turşusu, Dinatrium uridin-5'-monofosfat,  Adenozin-5'-monofosfor turşusu, Dinatrium-quanozin-5'-monofosfat Dinatrium inozin-5'-monofosfat)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_az.svg\"/><p>1 ölçü qaşığı təqribən 4,3 qramdır<br/>100 ml hazır körpə süd qatışığı ~ 13 q toz (3 ölçü qaşığı) + 90 ml su</p><p>* Uşağınızın gündəlik qidalanma miqdarı ilə bağlı pediatrınızla məsləhətləşin<br/>** 6 aydan sonra körpənizin əlavə qidalara ehtiyacı olur.<br/>*** 6 ayından sonra 180 ml suya bir qaşıq əlavə etməlisiniz.</p>"])}
    },
    "frisoPepAc": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso PEP AC"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> PEP AC"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnək südü zülallarına olan allergiyanın kəskin simptomlarının müalicəsi üçün"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso PEP AC  tərkibində yüksək dərəcədə hidrolizə edilmiş kazein zülalları var: peptidlərin 60%-i ‹ 3 amin turşusundan ibarətdir.</li><li>Friso PEP AC laboratoriya şəraitində T-hüceyrələrinin proliferasiyasına və iltihab əleyhinə sitokinlərin sərbəst buraxılmasına səbəb olmur.</li><li>Laktozasız, hər hansı zülal çirklənməsinin qarşısını almaq üçün.</li><li>İmmuniteti dəstəkləyən nukleotidlərlə.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quru qlükoza siropu, Bitki yağları, dərin hidrolizə edilmiş kazein, limon turşusunun mono və diqliseridlərinin efirləri (emulqator), L-sistein, Xolin xlorid, L- triptofan, Taurin, Mezo-inozitol, Lesitin (emulqator), L-karnitin, Minerallar: Kalsium fosfat, Maqnezium xlorid, Kalium xlorid, Kalsium karbonat, Dəmir sulfat, Sink sulfat, Mis sulfat, Manqan sulfat, Kalium yodid, Natrium selenit, <strong>Vitaminlər:</strong> Natrium L- askorbat, DL-α-tokoferol asetat, L-askorbil palmitat, Nikotinamid, D-kalsium pantotenat, Riboflavin, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Fitaminadion, D-biotin, Xolekalsiferol, Sianokobalamin, <strong>Nukleotidlər:</strong> Sitidin-5'-monofosfor turşusu, Dinatrium uridin-5'-monofosfat, Adenozin-5'-monofosfor turşusu, Dinatrium quanozin-5'-monofosfat, Dinatrium inozin-5'-monofosfat."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-8_az.svg\"/><p>1 ölçü qaşığı təqribən 4,3 qramdır<br/>100 ml hazır körpə süd qatışığı ~ 13 q toz (3 ölçü qaşığı) + 90 ml su</p><p>* Uşağınızın gündəlik qidalanma miqdarı ilə bağlı pediatrınızla məsləhətləşin<br/>** 6 aydan sonra körpənizin əlavə qidalara ehtiyacı olur.<br/>*** 6 ayından sonra 180 ml suya bir qaşıq əlavə etməlisiniz.</p>"])}
    },
    "frisoHa1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso HA 1"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> HA 1"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ailə tarixçəsinə əsaslanaraq risk altında olan körpələr üçün inək südü zülal allergiyası (İSZA) inkişaf riskini azaltmağa kömək etmək üçün qida"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 6 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso Gold HA 1 inək südünə qarşı həssaslığın qarşısını alan qismən hidrolizə edilmiş zərdab zülallarına malikdir.</li><li>QOS-un sağlam bağırsaq florası üçün faydalı olduğu tədqiqatlardan məlumdur.</li><li>DHA/ARA görmə və beyin inkişafı dəstəkləyir.</li><li>Nukleotidlər immun sisteminin inkişafını stimullaşdırır.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktoza, Bitki yağları, Tək hüceyrəli yağ (Mortierella alpina), Zərdab zülalı hidrolizatı, Maltodekstrin, QOS,  Mono- və diqliseridlərin limon turşusu efirləri (emulqator), Balıq yağı, Xolin xlorid,  Taurin, Mezo-inozitol, L-karnitin, Soya lesitin (emulqator), Limon turşusu (turşu tənzimləyicisi), Minerallar (Kalsium fosfat, Maqnezium xlorid, Kalium sitrat, Natrium sitrat, Kalium hidroksid,  Natrium xlorid, Dəmir sulfat, Sink sulfat, Mis sulfat, Kalium yodid, Manqan sulfat, Natrium selen), Vitaminlər (Natrium L-askorbat, DL-α-tokoferil asetat,  L-Askorbil palmitat, Nikotinamid, Kalsium D-pantotenat, Riboflavin, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Fitomenadion, D-Biotin, Xolekalsiferol, Sianokobalamin, Nukleotidlər (Sitidin-5'-monofosfor turşusu, dinatrium uridin-5' - monofosfat, Adenozin-5'- monofosfor turşusu,  Dinatrium quanozin-5' – monofosfat, Dinatrium inozin-5'-monofosfat,)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-9_az.svg\"/><p>1 ölçü qaşığı təqribən 4,3 qramdır<br/>100 ml hazır körpə süd qatışığı ~ 13 q toz (3 ölçü qaşığı) + 90 ml su</p><p>* Uşağınızın gündəlik qidalanma miqdarı ilə bağlı pediatrınızla məsləhətləşin</p>"])}
    },
    "frisoHa2": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso HA 2"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friso<sup>®</sup> HA 2"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ailə tarixçəsinə əsaslanaraq risk altında olan körpələr üçün inək südü zülal allergiyası (İSZA) inkişaf riskini azaltmağa kömək etmək üçün qida"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 aydan 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Friso HA 2 inək südünə qarşı həsaslığın qarşısını alan qismən hidrolizə edilmiş zərdab zülallarına malikdir.</li><li>QOS-un sağlam bağırsaq florası üçün faydalı olduğu tədqiqatlardan məlumdur.</li><li>DHA/ARA görmə və beyin inkişafı dəstəkləyir.</li><li>Nukleotidlər immun sisteminin inkişafını stimullaşdırır.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laktoza, Qatı qlükoza, Bitki yağları, Tək hüceyrəli yağ (Mortierella alpina), Zərdab zülalı hidrolizatı, Maltodekstrin, QOS,  Mono- və diqliseridlərin limon turşusu efirləri (emulqator), Balıq yağı, Xolin bitartrat,  Taurin, Mezo-inozitol, L-karnitin, Soya lesitin (emulqator), Limon turşusu (turşu tənzimləyicisi), Minerallar (Kalsium fosfat, Kalium sitrat, Maqnezium xlorid,  Maqnezium fosfat, Natrium xlorid,  Natrium sitrat, Kalium hidroksid,  Dəmir sulfat, Sink sulfat, Mis sulfat, Manqan sulfat, Kalium yodid, Natrium selen), Vitaminlər (Natrium L-askorbat, DL-α-tokoferil asetat,  L-Askorbil palmitat, Nikotinamid, Kalsium D-pantotenat, Riboflavin, Tiamin hidroxlorid, Retinol asetat, Piridoksin hidroxlorid, Fol turşusu, Fitomenadion, D-Biotin, Xolekalsiferol, Sianokobalamin, Nukleotidlər (Sitidin-5'-monofosfor turşusu, dinatrium uridin-5' - monofosfat, Adenozin-5'- monofosfor turşusu,  Dinatrium quanozin-5' – monofosfat, Dinatrium inozin-5'-monofosfat,)."])},
      "preparationTableExtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<img class=\"table\" src=\"/img/products/preparation-table-4_az.svg\"/><p>1 ölçü qaşığı təqribən 5,0 qramdır<br/>100ml of ready Infant Formula ~ 15g of powder (3 levelled scoops) + 90ml of water</p><p>* Uşağınızın gündəlik qidalanma miqdarı ilə bağlı pediatrınızla məsləhətləşin<br/>** 6 aydan sonra körpənizin əlavə qidalara ehtiyacı olur.</p>"])}
    },
    "frisolacGoldComfortAr": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac Gold Comfort AR"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisolac<sup>®</sup> Gold Comfort AR"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qastroezofaqal reflyuksun qidalanma ilə müalicə edilməsi."])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğulduğu gündən 12 aya qədər"])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li>Frisolac Gold Comfort AR-ın tərkibində reflyuks sürətini azaltmaqda təsirli olduğu sübut edilmiş optimal miqdarda Keçibuynuzu ağacının lifləri var.</li><li>GOS prebiotiklər sağlam bağırsaq mikrobiotasını dəstəkləyir</li><li>Qismən hidrolizə edilmiş zərdab zülalı mədə boşalmasını sürətləndirir.</li><li>DHA və ARA öyrənmə və idrak inkişafını dəstəkləyir.</li><li>Tam laktoza: körpələr üçün optimal karbohidratdır.</li></ul>"])},
      "ingredients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quru süd, Yağsızlaşdırılmış süd, Deminerallaşdırılmış süd zərdabı, Laktoza, Zərdab zülal konsentratı, Bitki yağları, Zərdab zülalı hidrolizatı, Keçibuynuzu ağacının lifləri, Maltodekstrin, QOS,  Kalsium fosfat, Emulqator (mono və diqliseridlərin sitrus turşusu mürəkkəb efiri), Balıq yağı, Kalium sitrat, Kalsium karbonat, Xolin xlorid, Natrium xlorid, Tək hüceyrəli yağ (Mortierella alpina), Maqnezium sitrat, Kalium xlorid, Natrium L-askorbat, Maqnezium xlorid, Mezo inozitol, Taurin, Dəmir sulfat, Emulqator (günəbaxan lesitini), Kalsium hidroksid, L-fenilalanin, L-tirozin, sink sulfat, Dinatrium uridin- 5'-monofosfat, L-askorbil palmitat, Kalsium D-pantotenat, Antioksidant (tokoferol zəngin ekstrakt), Sitidin-5'-monofosfor turşusu, DL-α-tokoferol asetat, Dinatrium inozin-5'-monofosfat, Nikotinamid, Adenozin-5'-monofosfor turşusu, L-karnitin tartrat, Dinatrium quanozin-5 '-monofosfat, Mis sulfat, Tiamin hidroxlorid, Riboflavin, Retinil asetat, Piridoksin hidroxlorid, Manqan sulfat, Fol turşusu, Kalium yodid, Fitomenadion, Natrium selenit, D-biotin, Xolekalsiferol, Sianokobalamin."])},
      "preparationTableUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/img/products/preparation-table-1_az.svg"])}
    }
  }
}